var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-6 mb-3 pt-4",staticStyle:{"margin-top":"14px","border-radius":"16px"},attrs:{"outlined":""}},[_c('form-fazenda-dialog',{on:{"new-fazenda":function($event){return _vm.novaFazenda($event)}},model:{value:(_vm.dialogFazenda),callback:function ($$v) {_vm.dialogFazenda=$$v},expression:"dialogFazenda"}}),_c('v-card-title',{staticClass:"pa-0",staticStyle:{"text-shadow":"grey 0.1em 0.1em 0.2em"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-form',{ref:"form"},_vm._l((_vm.cadastros),function(cad,index){return _c('v-card',{key:cad.id,staticClass:"pa-5 mb-2 mt-2",staticStyle:{"border-radius":"16px"},attrs:{"outlined":"","track-by":"cad.id"}},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{ref:"selectFazenda",refInFor:true,attrs:{"items":_vm.fazendas,"item-text":"razao_social","item-value":"id","label":"Fazenda*","rules":[
              _vm.rules.required,
              _vm.rules.distinct(
                _vm.cadastros.map(v => v.fazenda_id),
                'Esta Fazenda já foi cadastrada para este Produtor.',
              ),
            ],"loading":_vm.$asyncComputed.fazendas.updating,"autocomplete":"off","validate-on-blur":"","dense":"","disabled":!cad.ehNovo,"outlined":"","rounded":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-container',{staticClass:"rounded-lg mx-n2",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.razao_social)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.cnpj)+" ")])],1)],1)],1)]}}],null,true),model:{value:(cad.fazenda_id),callback:function ($$v) {_vm.$set(cad, "fazenda_id", $$v)},expression:"cad.fazenda_id"}},[_c('template',{staticStyle:{},slot:"prepend-item"},[_c('v-btn',{staticClass:"text-none text-white",attrs:{"color":"success","dark":"","small":"","block":"","rounded":""},on:{"click":() => { _vm.dialogFazenda = true, _vm.currentCard = index }}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Cadastrar Nova Fazenda ")],1)],1)],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####################'),expression:"'####################'"}],attrs:{"dense":"","autocomplete":"off","rules":[
              _vm.rules.required,
              _vm.rules.distinct(
                _vm.cadastros.map(v => v.cod_produtor_cliente),
                'Este código já foi utilizado.',
              ),
            ],"label":"Código do Produtor Cliente*","validate-on-blur":"","disabled":!cad.ehNovo,"outlined":"","rounded":""},model:{value:(cad.cod_produtor_cliente),callback:function ($$v) {_vm.$set(cad, "cod_produtor_cliente", $$v)},expression:"cad.cod_produtor_cliente"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####################'),expression:"'####################'"}],attrs:{"dense":"","autocomplete":"off","rules":[
              _vm.rules.required,
              _vm.rules.distinct(
                _vm.cadastros.map(v => v.cod_produtor_fornecedor),
                'Este código já foi utilizado.',
              ),
            ],"label":"Código do Produtor Fornecedor*","validate-on-blur":"","disabled":!cad.ehNovo,"outlined":"","rounded":""},model:{value:(cad.cod_produtor_fornecedor),callback:function ($$v) {_vm.$set(cad, "cod_produtor_fornecedor", $$v)},expression:"cad.cod_produtor_fornecedor"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tecnicos,"item-text":(item) => item.nome || item.razao_social,"item-value":"id","label":"Técnico *","loading":_vm.$asyncComputed.tecnicos.updating,"autocomplete":"off","rules":[_vm.rules.required],"validate-on-blur":"","outlined":"","rounded":"","dense":""},model:{value:(cad.tecnico_id),callback:function ($$v) {_vm.$set(cad, "tecnico_id", $$v)},expression:"cad.tecnico_id"}})],1)],1),_c('v-card-actions',{staticClass:"py-0 mt-2 mx-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"text-none text-white justify-end",attrs:{"small":"","color":"red darken-3","dark":"","rounded":"","title":"Excluí a fazenda do produtor","disabled":_vm.cadastros.length <= _vm.min},on:{"click":function($event){return _vm.removeEspacoCad(cad.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline left ")]),_vm._v(" Excluir ")],1)],1)],1)}),1),_c('v-btn',{staticClass:"text-none text-white mx-2 my-2",attrs:{"small":"","dark":"","color":"success","title":"Inclui uma nova Fazenda.","rounded":""},on:{"click":_vm.addEspacoCad}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Incuir nova fazenda ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }